
.filter-button-cancel {
    background-color: white;
    color: #008C95;
    border: 2px solid #008C95;
}
.filter-buttons-styles {
    .MuiButton-root {
        font-family: 'Open sans', sans-serif;
        font-weight: 400;
        padding: 5px 10px;
        border-radius: 29px;
        font-size: 15px;
    }
}
.filter-button-apply {
    border-radius: 16px;
    background-color: #008C95;
    color:#fff;
}
.check-color {
    color:#fff;
}
.header-dialog-resources {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    background-color: #008C95;
}
.filter-icon {
    background-color: #008C95;
    
}
.resource-category-buttons {
    color: #fff;
    background-color: #008C95;
    border-radius: 5px;

}
.collection-heading {
    margin-top: 20px;
}

.resources-panel {
   width: 100%;
   min-height: 630px;
   .slick-dots{
        position: static;
        max-height: 50px;
        overflow-x: hidden;
        overflow-y: scroll;
   }
}

.widget-resources-panel {
    min-height: calc(100vh - 106px) !important;
 }

.slider-element {
    border-radius: 16px;
    height: 250px;
}

.report-image {
   margin: auto;
   text-align: center;
   align-items: center;
   justify-content: center;
   padding:1rem;
   width:180px;
   height:175px;
   display: flex;
}
.report-image-height {
    max-height:150px;
}
.download-report {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px;
}
.filter-icon {
    background-color:#008C95
}
.chipSelected {
    color: white;
    background: #008C95;  
}
.chipStyle {
    color: #008C95;
    border: 2px solid #008C95;
    background: #FFFFFF;
}

