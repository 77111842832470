.checkin-container{
    min-height:450px;
    background:#008C95;
    z-index:1
}
.button-pointer{
    cursor:pointer;
}
.activity-detail-height{
    min-height:350px;
}
.volume-button{
    color:#008C95;
    cursor:pointer;
}
.font-18{
    font-size: 18px;
}
.img-height-330{
    max-height: 330px;
}
.countdown-height{
    min-height: 230px;
}
.countdown-time{
    font-size:30px;
    height:150px;
}
.countdown-edit-button{
    color:#008C95;
    border:2px solid #008C95;
    min-width: 120px;
}
.status-heading-background{
    background:#ebf2f5;
}



//----------------------ShowActivityDetails--------------------

.showActivityDetails-header{
    background:'#ebf2f5'
}
.showActivityDetails-step-height{
    min-height:400px;
}

//----------------------------NewGroup------------------------

.font-550{
    font-weight: 550;
}
.newgroup-search-icon{
    height:20px;
    width:20px;
}
.input-text-field{
    height:20px;
    width:20px;
    background:#008C95;
    border:2px solid white
}
.select-input-field{
    background:#e0e0d1;
    border-bottom:3px solid #008C95;
}
.unselect-input-field{
    background:#e0e0d1;
}
.contact-img{
    border:3px solid #008C95;
}