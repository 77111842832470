/*============ Activity Preview Style =============*/
// Schedule
.backbuttonForPreviewLinkIframe {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background:#008C95;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 8px 19px;
    .MuiSvgIcon-root,.linkButton{
        color: #FFFFFF;
    }
}
.half-visibility {
    opacity: 0.5;
}
.backbuttonForPreviewLinkIframe:hover{
    color: #008C95;
    background: #FFFFFF;
    border: 1px solid #008C95;
    .MuiSvgIcon-root,.linkButton{
        color: #008C95;
    }
}
.scheduleTitle {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #454D51;
}
.headerTitle {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: #454D51;
    padding-top: 10px;
}
.headerButton {
    cursor: pointer;
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    background: #008C95;
    color: white;
    min-width: 88px;
    width: auto;
    height: 40px;
    border: 0px;
    border-radius: 4px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.headerButton:hover {
    background: rgba(64, 192, 228, .9);
}
.headerButton:focus {
    background: #008C95;
}
.headerButton:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.tabtitle-selected {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #454D51;
}
.tabtitle {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: rgba(61, 79, 87, 0.45);
}
.videoInput{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 15px;
    font-style: normal;
    background: transparent;
    border-radius: 5px;
}
.inputTitle {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #3D4F57;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
    -webkit-appearance: none;
    -webkit-box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
    -moz-box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
    // .MuiInputBase-input{
    //     font-family: 'Lato';
    //     font-weight: 600;
    //     font-size: 18px;
    //     font-style: normal;
    //     color: #3D4F57;
    // }
}
.desciption {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #454D51;
    border-radius: 5px;
    margin-top: 5px;
    padding-left: 10px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
    -webkit-appearance: none;
    // .MuiInputBase-input{
    //     font-family: 'Lato';
    //     font-weight: 400;
    //     font-size: 14px;
    //     font-style: normal;
    //     color: #454D51;
    //     padding: 4px;
    // }
}
.listItem {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #454D51;
}
.listItemTitle {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #454D51;
}
.addSchedule {
    cursor: pointer;
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
    border: 2px solid #008C95;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.buttonWithBorder {
    cursor: pointer;
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    background: white;
    color: #008C95;
    border: 2px solid #008C95;
    height: 40px;
    min-width: 152px;
    width: auto;
    border-radius: 4px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.buttonWithBorder:hover, .buttonWithBorder:focus {
    background: #008C95;
    color: white;
}
.buttonWithBorder:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.steplibButton {
    cursor: pointer;
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
    border: 0px ;
    background: white;
    height: 40px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.menuEditItem {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    color: #3D4F57
}
.meniDeleteItem {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    color: #DF3C26
}
.scheduleDone {
    cursor: pointer;
    font-family: "open sans", sans-sarif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
    border: 2px solid #008C95;
    min-width: 96px;
    width: auto;
    height: 40px;
    background: white;
    border-radius: 4px;
}
.scheduleDone:hover, .scheduleDone:focus{
    color:  white;
    background: #008C95;
}
.scheduleDone:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.deleteButton {
    font-size: xx-large;
    color: #F05576;
}
.weekDays-selected {
    cursor: pointer;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    font-style: normal;
    color: white;
    border: 0px ;
    background: #008C95;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.weekDays {
    cursor: pointer;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    font-style: normal;
    color: rgba(64, 192, 228, 0.4);
    border: 2px solid #008C95;
    background: #FFFFFF;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.optionsRow{
    background:#EBF2F5;
    border-radius:4px;
    height:42px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selectBox{
    cursor: pointer;
    width:49%;
    height:34px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.unselectBox{
    cursor: pointer;
    width:49%;
    height:34px;
    box-shadow:1px 1px 1px #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.step-input-title, .steptitle {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #454D51;
}
.step-input-note, .stepnote {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    color: #8F9497
}
.helpbutton {
    cursor: pointer;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    background: #FFFFFF;
    color: #008C95;
    width: 40px;
    height: 40px;
    border: 2px solid #008C95;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.helpbutton:hover{
    color: #FFFFFF;
    background:#008C95;
}
.helpbutton:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.backbutton {
    cursor: pointer;
    width: 40px;
    height: 40px;
    color:black;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.backbutton:hover, .backbutton:focus {
    color: #FFFFFF;
    background:#008C95;
    .MuiSvgIcon-root,.linkButton{
        color: #FFFFFF;
    }
}
.backbutton:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.linkButton {
    cursor: pointer;
    color: #454D51;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
}
.addstep {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
}
.exitDialog1{position: relative;
	max-width: 100%;
	margin: 0px 0px 0.4em;
	padding: 0px;
	color: rgb(89, 89, 89);
	font-weight: 600;
	text-align: center;
	text-transform: none;
	overflow-wrap: break-word;
}
.plusbutton {
    color: #008C95;
}
.titleIcon {
    width: 28px;
    height: 28px;
    background: #EBF2F5;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
    // margin-top: 6px;
}
.select-font {
    font-size: 11px;
    color: #8F9497;
    margin-bottom: 0px;
}
.select-list {
    padding: 6px 0px 7px;
}
.dilogBox{
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    border-radius: 8px;
    background: #FFFFFF;
}
.stepContent {
    background: #F7F7F7;
    box-sizing: border-box;
    border-radius: 4px;
}
.stepContentDrag {
    background: #F7F7F7;
    border: 1px dashed #E7E7E7;
    box-sizing: border-box;
    border-radius: 4px;
}
.warningText{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    color: #454D51;
}
.group-chip{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #3D4F57;
    background: #EBF2F5;
}
.button-scratch{
    cursor: pointer;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #2CB2DC;
    background: none;
}
.oe-text-primary {
    cursor: pointer;
    color: #2CB2DC;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.oe-bg-primary {
    background: #EBF2F5;
}
.chip-array-selected {
    .MuiChip-root {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #3D4F57;
        background-color: #EBF2F5;
    }
    .MuiChip-clickable:hover, .MuiChip-clickable:focus {
        background-color:#EBF2F5;// rgb(206, 206, 206);
    }
}
.chip-array {
    .MuiChip-root {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #3D4F57;
        background-color: lightgray
    }
    .MuiChip-clickable:hover, .MuiChip-clickable:focus {
        background-color:lightgray// rgb(206, 206, 206);
    }
}
.inputTitleErr {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #3D4F57;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom:1px solid var(--errorMessage);
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
    -webkit-appearance: none;
    -webkit-box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
    -moz-box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
}
.step-input-titleErr{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #454D51;    
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom:1px solid var(--errorMessage);
}
.sweet-alert  {
    .btn {
      padding: 0.3rem 2rem;
      font-size: 1rem;
    }
  }
  .oe-text-primary:hover, .oe-text-primary:focus, .oe-text-primary:active{
    background-color:#008C95;
    color: #FFFFFF;
    .MuiButtonBase-root, .button-scratch{
        background: none;
        color: #FFFFFF;
    }
    .addstep{
        color: #FFFFFF;
    }
  }
.cardbutton:hover, .addstepbutton:hover, .cardbutton:focus, .addstepbutton:focus {
    color: #FFFFFF;
    background:#008C95;
    .MuiButtonBase-root, .button-scratch{
        background: none;
        color: #FFFFFF;
    }
    .addstep{
        color: #FFFFFF;
    }
}
.cardbutton:active, .addstepbutton:active, .backbutton:active {
    background:#008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}

.reward-style {
    position: absolute;
    text-align: center;
    top: 8px;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    z-index: 1;
}
.list-card{
    border-radius: 5px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .20);
    border: none;
    margin-bottom: 10px;
    position: relative;
    background-color: white;
}
.list-card-slider {
    border-radius: 5px;
    border:1px solid lightgray;
   
    margin-bottom: 10px;
    position: relative;
    background-color: white;
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 12px;
    padding-right: 12px;
}
.list-card-drag{
    border-radius: 5px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
    border: none;
    margin-bottom: 10px;
    position: relative;
    // border: 1px dashed #E7E7E7;
    box-sizing: border-box;
}
.warning-div {
    width: 80px; 
    height: 80px; 
    border-width: 4px; 
    border-style: solid; 
    border-color: rgb(238, 162, 54); 
    border-radius: 50%; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px; 
    margin-bottom: 0px;
    position: relative; 
    box-sizing: content-box; 
    animation: 0.75s ease 0s infinite alternate none running pulseWarning;
}
.w-span-1{
    position: absolute; 
    width: 5px; 
    height: 47px; 
    left: 50%; 
    top: 10px; 
    border-radius: 2px; 
    margin-left: -2px; 
    background-color: rgb(240, 173, 78); 
    animation: 0.75s ease 0s infinite alternate none running pulseWarningIns;
}
.w-span-2{
    position: absolute; 
    width: 7px; 
    height: 7px; 
    border-radius: 50%; 
    margin-left: -3px; 
    left: 50%; 
    bottom: 10px; 
    background-color: rgb(240, 173, 78); 
    animation: 0.75s ease 0s infinite alternate none running pulseWarningIns;
}
.rct-block-content {
    .add-step-container{
        .MuiButton-root {
            text-decoration: none;
            background-color: #FFFFFF;
            .MuiButton-label {
                color: #008C95;
            }
        }
        .MuiButton-root:hover {
            text-decoration: none;
            background-color: #008C95;
            .MuiButton-label {
                color: #FFFFFF;
            }
        }
        @media (hover: none){
            .MuiButton-root:hover {
                background-color: transparent;
                .MuiButton-label {
                    color: #008C95;
                }
            }
        }
    }
}
.MuiDialog-container {
    .MuiToolbar-root{
        .MuiPickersToolbarText-toolbarBtnSelected {
            font-weight: 900;
        }
        .MuiPickersTimePickerToolbar-ampmLabel{
            font-size: 30px;
        }
    }
    .MuiTypography-h2{
        font-family: 'Lato';
        font-weight: 400;
        font-size: 40px;
        font-style: normal;
    }
}
.img-object-fit {
    object-fit: contain;
}
.footer-style{
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.canvas-container {
    height: 56vh;
    }

//icon coilor; #3c4043;
.room > h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
}
    
.local-participant {
    text-align: center;
    margin-bottom: 2em;
}
.remote-participants {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 2em 2em;
}
.participant {
    display: inline-block;
}
.participant:last-child {
    margin-right: 0;
}
.participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
}
.activity-icon-progress {
    width:24px;
    height:24px;
    font-size: 15px;
    text-align: center;
    background-color: #008C95;
    font-weight: 500;
    color:white;
}   
.activity-icon-progressa {
    width:24px;
    height:24px;
    font-size: 14px;
    text-align: center;
    background-color: #58B479;
    font-weight: 500;
    padding-bottom: 3px;
    color:white;
    border-radius: 50%;
}  
.activity-step {
    font-size: 12px;
    margin-top: 2px;
    text-overflow: ellipsis;
  overflow: hidden;
} 
video {
    width: 100%;
    max-height: 320px;
    max-width: 600px;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
}
.status-label {
    font-size: 12px;
    border-radius:3px;
    color:white;
    
    margin-top: 2px;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-right: 5px;
    margin-right: 3px;
    width:100;
   

}
.download-label {
    font-size: 12px;
    border-radius:3px;
    color:white;
    text-align: center;
    background-color: #008C95;
    cursor: pointer;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-right:30px;
   
    width: 80px;
   

}

.ls-dashboard-wrapper {
    margin: auto;
  
    .chat-list {
        ul {
            .user-list-item {
                 color:#212529;
                 cursor: pointer;
                 background-color: #FFFFFF;
                 border-radius: 5px;
                 box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
                 padding: 1rem;
                 transition: all 0.3s ease-in-out;
                .media-left {
                    .badge {
                       position: absolute;
                       bottom: 0px;
                    }
                 }
                 .msg-count {
                    .badge {
                       height: 1.5rem;
                       width: 1.5rem;
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       padding: 0;
                    }
                 }
                 &.item-active {
                    background-color: rgb(214,234,243);
                    color: #464D69;
                    cursor: pointer;
                    border-radius: 5px;
                    box-shadow: 0 1px 15px 1px rgb(252, 252, 253);
                    padding: 1rem;
                    transition: all 0.3s ease-in-out;
                    
                 }
                 &:hover {
                   &:not(.item-active) {
                    background-color: #FFFFFF;
                    }
                 }
            }
        }
    }
     
}
.hoveritem-color {
    background-color: #dae3e7;
}
.unconfirmed-activities {
    display:inline-block;
    margin-left:2px;
    color:gray;
    cursor: pointer;
}
.unread-messages {
    display:inline-block;
    margin-left:2px;
     color:gray;
}
.resource-scroll{
    margin: auto;
    height: calc(100vh - 160px);
    overflow-y: scroll;
}
.bg-lifesherpa {
    background-color: #008C95;
    color: #FFFFFF;
}
.activity-completed  {
    background-color: #008C95;
}
     
@media(max-width: 1023px) {
    // .ls-dashboard-wrapper {
    //    // height: calc(100vh - 138px);
    // }
    .resource-scroll{
        height: calc(100vh - 218px);
    }
}
.assign-activity-container-layout {
    .assign-activity-iframe {
        height: 100vh;
        border-width: 0px;
    }

}
.mobile-activity-container {
    .assign-activity-iframe {
        height: calc(100vh - 65px);
        border-width: 0px;
    }
}
@media(max-width: 780px) {
    // .ls-dashboard-wrapper {
    //    // height: calc(100vh - 180px);
    // }
    .resource-scroll{
        height: calc(100vh - 260px);
    }
    .setcurrent-status-dialog-popup {
        .MuiDialog-paperFullWidth {
            width: calc(100% - 0px);
        }
        .MuiDialog-paper {
            margin: 0px;
        }
    }
    .assign-activity-container-layout {
        .assign-activity-iframe {
            height: 100vh;
            border-width: 0px;
        }
    
    }
}

.add-activity-dilog{
    width:200px;
     border-radius: 8;
     margin:10;
     margin-top:"auto";
     margin-left:'auto'
}
.filter-dilog{
    max-width:500px;
    height:800px;
}
.select-filter{
    border-radius: 16px;
    background: #008C95;
    color:#fff;
    height: 25px;
    cursor: pointer;
}
.unselect-filter{
    border-radius: 16px;
    height: 25px;
    background: #fff;
    cursor: pointer;
    color: #008C95;
    border: 2px solid #008C95;
}
.filter-apply-button{
    border-radius: 16px;
    background: #008C95;
}
.clear-filter-button{
    border-radius: 16px;
    background: white;
    color: #008C95;
    border: 2px solid #008C95;
}
.selected-tab{
    border-bottom:"3px solid white"
}
.list-type{
    background: lightgray;
    border-radius: 8px;
}
.select-list-type{
    background: #008C95;
    border-radius: 8px;
    color: white;
}
.unselect-list-type{
    color:#008C95;
}
.inprogress-heading{
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    background:#008C95;
    margin-bottom:-20px;
}   
.select-inprogress-activity{   
    background:rgb(214,234,243); 
    //background: rgb(255, 255, 255);
    margin-top: -23px;
    border: 2px solid #008C95;
}
.inprogress-activity{   
    //background:rgb(214,234,243); 
    background: rgb(255, 255, 255);
    margin-top: -23px;
    border: 2px solid #008C95;
}
.expired-activity{
    background: rgb(255, 255, 255);
    margin-top: -23px;
} 
.select-activity{
    background:rgb(214,234,243);
    margin-top:-23px;
}
.complete-step{
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.activity-completed{
    height:5px;
    border-radius:2px;
}
.activity-remain{
    background:lightgray;
    height:5px;
    border-bottom-right-radius:2px;
    border-top-right-radius:2px;
}
.summary-list{
    background: rgb(255, 255, 255);
    border-bottom: 3px solid #008C95;
}
.text-500{
    font-weight: 500;
}
.text-400{
    font-weight: 400;
}
.theame-color{
    background:#008C95
}
.on-focus{
    cursor: pointer;
}
.form-body-background{
    background-color: #f5f5f5;
}
.form-submit-button{
    height:40px;
    background:#008C95;
    min-width: 120px;
}
.select-view-flow {
    width:85px;
    height: 30px;
    border: 1px solid #008C95;
   
}
.cps {
    cursor: pointer;
}
.selected-view {
    background-color: #008C95;
    color: #fff;
}
.activity-title {
    
    overflow: hidden;
    text-overflow: ellipsis;
   
}
.activities-inprogress-title {
    color: rgb(0, 140, 149);
    font-size: 17px;
    font-weight: 500;
}
.activity-details-height{
    min-height:400px;
}
.activity-preveiw-configurator-details-height{
    height: calc(100vh - 100px);
    overflow-y: auto;
}
.activity-preveiw-ls-portal-details-height{
    height: calc(100vh - 220px);
    overflow-y: auto;
}
.activity-details-height-for-list-view{
    overflow-y: auto;
    overflow-x: hidden;
}
.activity-flow-view-button {
    width: 70px;
}
.activity-previev-height{
    min-height:700px;
}
.complete-activity-page-height{
    min-height:470px;
}
.complete-activity-ok-button{
    margin-top:50%;
    background:#008C95;
}
.step-complete-bar{
    width:200px;
}

.btn-primary {
    background-color: #008C95;
    color: #FFFFFF ;
    border: 2px solid #008C95;
    min-height: 40px;
    padding: 9px 10px;
}
.btn-continue-idle {
    background-color: #008C95;
    color: #FFFFFF ;
    border: 2px solid #008C95;
    min-height: 40px;
    padding: 9px 10px;
}
.complete-activity-rewards-img-text{
    margin-left:25px;
    margin-top:-100px;
    z-index:15;
    font-size: 40px;
}
.width-90{
    width: 90px;
}
.width-27{
    width:27px
}
.margin-t30{
    margin-top: 30%;
}
.margin-t50{
    margin-top:50%
}
.sweet-alert-text{
    font-family:'Lato';
    font-weight:400;
    font-size: 16px;
}
.ok-size{
    width:80px;
    font-size:16px;
}
.calender-height{
    height:300px
}
.selected-option{
    border-bottom:3px solid white
}
.diamond-image-index{
    z-index: 10;
}
.diamond-text{
    margin-left:-26px;
    margin-top:8px;
    z-index: 15;
}

.scheduleButton {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
}
.moreButton {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
}

.client-item-padding {
    padding-top: 45px;
    padding-bottom: 10px;
}

.setLoadingHeight{
    min-height: 400px;
}

.preview-activity-height{
    height: calc(100vh - 190px);
}
.preview-activity-height-empty{
    height: calc(100vh - 160px);
}
.preview-complete-text{
    margin-top:30%;
}
.step-bar{
    width:200px;
}
.preview-step-height{
    height: 300px;
}

@media (max-width: 1023px) {
    .preview-activity-height{
        height: calc(100vh - 186px);
    }
    .preview-activity-height-empty{
        height: calc(100vh - 155px);
    }
}

@media (min-width: 576px) and (max-width: 765px) {
    .preview-activity-height{
        height: calc(100vh - 185px);
        height: calc(100vh - 155px);
    }
}
.activity-step-checked {
    font-size: 24;
    margin-right: 5px;
    color: rgb(31, 179, 31); 
}
.activity-step-unchecked {
    font-size: 24;
    margin-right: 5px;
    color: rgb(165, 163, 163); 
}
.activity-step-skipped {
    font-size: 18px;
    margin-right: 8px;
    color:#fff;
    background-color: rgb(243, 62, 62);
    margin-top: 3px;
    border-radius: 2px;
}
.activity-step-reference-url {
    text-decoration: underline;
}
.activity-step-thumb-down {
    color: rgb(243, 62, 62);
     font-size: 14px;
     display: inline-block;
}
.activity-step-thumb-up {
    color: rgb(31, 179, 31);
     font-size: 14px;
     display: inline-block;
}
.activity-list-veiw-font {
    font-size: 16px;
    display: inline-block;
}

.card-type {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: large;
}
.breadcomp-for-panel {
    .breadcrumb {
    margin-top: auto;
    margin-bottom: auto;
    }
}
.search-activity-field {
    .MuiFormControl-root {
        width: 100%;
    }
    width: 90%;
}
.search-ecounter-type {
    .MuiFormControl-root {
        width: 100%;
    }
    width: 100%;
}
.UnselectTab {
    color: black;
}
.user-status-profile-background {
    background: #ebf2f5;
}
.activities-list-inprogress-title {
    background: #008C95;
    margin-bottom: -25px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
}
.activities-filter-dialog-content {
    min-height: 400px;
}
.activities-list-item-border-inprogress {
  border: 2px solid #008C95;
  background: #fff;
  margin-top: -23px;
}
.activities-list-item-border {
    background: #fff;
    margin-top: -23px;
}
.base-font-color {
    color: gray !important;
    font-size: 18px !important;
}
.styleToCorseItem {
    background-color: #F4F7FA !important;
}
.form-cancel-button {
    color: #008C95 ;
    border: 2px solid #008C95;
    background-color: #FFFFFF;
    min-width: 120px;
    font-family: 'Open sans', sans-serif;
    min-height: 40px;
    font-weight: 400;
    padding: 9px 10px;
}
.countdown-edit-button {
    color: #008C95 ;
    border: 2px solid #008C95;
    background-color: #FFFFFF;
    min-width: 120px;
    font-family: 'Open sans', sans-serif;
    min-height: 40px;
    font-weight: 400;
    padding: 9px 10px;
}
.primary-button {
    background-color: #008C95;
    color:#FFFFFF;
    border: 1px solid  #008C95;
    min-width: 120px;
    font-family: 'Open sans', sans-serif;
    min-height: 40px;
    font-weight: 400;
    padding: 9px 10px;
}
.secondary-button {
    background-color:#FFFFFF;
    color:#008C95;
    border: 1px solid #008C95;
    min-width: 120px;
    font-family: 'Open sans', sans-serif;
    min-height: 40px;
    font-weight: 400;
    padding: 9px 10px;
}
.lifesherpa-poweredby-logo {
    padding-left: 10px;
}
.app-header {
    .lifesherpa-logo {
        margin: 10px 25px;
      }
    .ls-portal-header {
        height: fit-content;
        .header-content-box {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        }
    }
}
.stepDone {
    color:rgb(165, 163, 163);
}
.stepDo {
    color: rgb(31, 179, 31);
}
.list-card {
    .done-button-thumb-icon {
        color: rgb(31, 179, 31);  
    }
}
.status-list-input-options {
    label {
        width: 100%;
        cursor: pointer;
        font-size: 15px;
    }
    width: 100%;
}
.loading-height-for-preveiw {
    height: 100%;
}
.activity-preview-form-container {
    .form-body-background {
        hr {
            background-color: #008C95;
        }
        .form-label {
            background-color: #008C95;
            color: #FFFFFF;
            padding: 5px 10px;
            width: 100%;
            margin-bottom: 0px;
            font-size: 17px;
        }
        .form-margin { 
            margin: 2px 10px;
        }
        .mb-2 {
            margin-bottom: 0px !important;
        }
        p {
            font-size: 16px;
        }
        .custom-control-label {
            width: 100%;
            font-size: 15px;
        }
        .form-input-field {
            margin: 8px 10px;
            font-size: 16px;
        }
        .preveiw-options {
            margin: 0px;
            padding: 3px 10px;
        }
        .rct-picker {
            padding: 5px 10px;
        }
        .input-options-error {
            font-size: 14px;
            margin: 0px 10px 5px 14px;
            color: var(--errorMessage);
            font-family: 'Lato';
        }
    }
 }
 .activity-preview-dialog-actions {
    .activity-preveiw-skip-buttons {
        width: 100%;
        .MuiButton-root {
            font-family: 'Open sans', sans-serif;
            font-weight: 400;
            min-width: 100px;
            min-height: 3.2rem;
            border-radius: 24px;
            font-size: 17px;
        }
        .MuiButton-contained.Mui-disabled {
            opacity: 1;
        }
    }
 }
.activity-preview-dialog-content {
    overflow-x: hidden;
    .activity-preveiw-skip-buttons {
        width: 100%;
        .MuiButton-root {
            font-family: 'Open sans', sans-serif;
            font-weight: 400;
            min-width: 100px;
            min-height: 3.2rem;
            border-radius: 24px;
            font-size: 17px;
        }
        .MuiButton-contained.Mui-disabled {
            opacity: 1;
        }
    }
    .MuiButton-root {
        min-width: 120px;
        font-family: 'Open sans', sans-serif;
        min-height: 40px;
        font-weight: 400;
        padding: 8px 10px;
        font-size: 14px;
    }
    .MuiButton-contained.Mui-disabled {
        opacity: 1;
    }
    .activity-preview-form-container {
        .MuiButton-root {
            min-width: 130px;
            font-family: 'Open sans', sans-serif;
            min-height: 40px;
            font-weight: 400;
            padding: 8px 10px;
            font-size: 14px;
        }
    }
}

.notice-form-container {
    .MuiButton-root {
        min-width: 130px;
        font-family: 'Open sans', sans-serif;
        min-height: 40px;
        font-weight: 400;
        padding: 8px 10px;
        font-size: 14px;
    }
}
.configurator-activity-preview {
    .MuiDialogTitle-root {
        padding: 0px; 
    }
    .activity-preveiw-skip-buttons {
        .MuiButton-root {
            font-family: 'Open sans', sans-serif;
            font-weight: 400;
            min-width: 100px;
            min-height: 3.2rem;
            border-radius: 24px;
            font-size: 17px;
        }
        .MuiButton-contained.Mui-disabled {
            opacity: 1;
        }
    }
    .MuiButton-root {
        min-width: 120px;
        font-family: 'Open sans', sans-serif;
        min-height: 40px;
        font-weight: 400;
        padding: 8px 10px;
        font-size: 14px;
    }
    .MuiButton-contained.Mui-disabled {
        opacity: 1;
    }
    .activity-preview-form-container {
        .MuiButton-root {
            min-width: 130px;
            font-family: 'Open sans', sans-serif;
            min-height: 40px;
            font-weight: 400;
            padding: 8px 10px;
            font-size: 14px;
        }
    }
    .MuiDialog-paper {
        overflow-x: hidden;
        .MuiDialogContent-root {
          overflow-x: hidden;
        }
    }
}
.update-time-buttons {
    .MuiButton-root {
        min-width: 120px;
        font-family: 'Open sans', sans-serif;
        min-height: 40px;
        font-weight: 400;
        padding: 8px 10px;
        font-size: 14px;
    }
    .MuiButton-contained.Mui-disabled {
        opacity: 1;
    }
}
.update-passwords-button {
    .MuiButton-root {
        min-width: 120px;
        font-family: 'Open sans', sans-serif;
        min-height: 40px;
        font-weight: 400;
        padding: 8px 10px;
        font-size: 14px;
    }
    .MuiButton-contained.Mui-disabled {
        opacity: 1;
    }
}
 .next-button {
    background-color: #008C95;
    color: #FFFFFF ;
    border: 2px solid #008C95;
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    min-width: 100px;
    min-height: 3.2rem;
    border-radius: 30px;
    font-size: 17px;
}
.skip-button {
    background-color:#FFFFFF;
    color:  #008C95;
    border: 2px solid #008C95;
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    min-width: 100px;
    min-height: 3.2rem;
    border-radius: 30px;
    font-size: 17px;
}
.setcurrent-status-dialog-popup {
    .MuiDialogTitle-root {
        padding: 0px; 
    }
    .MuiDialogContent-root {
        padding: 0px; 
    }
    .MuiButton-root {
        min-width: 120px;
        font-family: 'Open sans', sans-serif;
        min-height: 40px;
        font-weight: 400;
        padding: 8px 10px;
        font-size: 14px;
    }
}
.schedules-list-dialog-popup {
    .MuiDialogTitle-root {
        padding: 0px; 
    }
    .MuiDialogContent-root {
        padding: 0px; 
    }
    .MuiDialog-paper {
        overflow-x: hidden;
    }
}
.activity-preview-dialog-popup {
    .MuiDialogTitle-root {
        padding: 0px; 
    }
}
.user-list-extra-info {
    .reply-messages-icon {
        color: #58B479;
    }
    .unconfirmed-activity-icon {
        color: #E7900D;
    }
}
.list_header {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 100;
    width: 100%;
  }
  .lifesherpa-main-container {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .app-container {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
  }
  .list-header-relative {
    width: 100%;
  }


.box-word-layout {
    word-break: break-all;
}
.add-calender-event-container {
    .form-control {
        width: 100%;
    }
}
.activity-form-number-options {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.take-photo-camera-dialog-popup {
    .loader-overlay {
        z-index: 101;
    }
    .camera-dialog-content {
        margin-bottom: -5px !important;
    }
    .MuiDialog-paperWidthSm {
        max-width: 900px !important;
        width: 100%;
        height: 100%;
    }
}

.box-word-layout {
    word-break: break-all;
}
.add-calender-event-container {
    .form-control {
        width: 100%;
    }
}
.camera-popup-dialog {
    .loader-overlay {
        z-index: 101;
    }
    .camera-popup-dialog-content {
        margin-bottom: -5px !important;
        video {
            width: 100%;
            height: 100%;
            max-height: 700px;
            max-width: 900px;
            object-fit: fill;
            border-radius: 0px;
        }
        .hide-camara {
            visibility: hidden;
            height: 0px;
        }
        .take-picture-camera-icon  {
            font-size: 50px;
        }
        .webcam-container {
            height: 100%;
            width: 100%;
        }
    }
    .fix-min-style-content {
        min-height: 300px;
        min-width: 350px;
    }
    .MuiDialog-paperWidthSm {
        max-width: 900px !important;
        width: 100%;
        height: 100%;
    }
    .camera-popup-dialog-actions {
        .take-picture-message-camera {
           font-size: 18px;
        }
        .outer-circle {
            border-radius: 50%;
            width: 75px;
            background-color: rgba(255, 255, 255, 0.4);
            z-index: 1;
            position: relative;
            height: 75px;
        }
        .inner-circle {
            border-radius: 50%;
            height: 44px;
            width: 44px;
            background: #fff;
            margin: -22px 0 0 -22px;
            z-index: 2;
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 38px;
        }
        .camera-switch {
            position: absolute;
            right: 16px;
            .MuiButtonBase-root {
                background-color: #fff;
            }
        }
    }
    .loader-overlay {
        z-index: 101;
    }
}

@media(max-width: 450px) {
    .camera-popup-dialog {
        .MuiDialog-paperWidthSm {
            max-width: 900px !important;
            width: 100%;
            height: 100%;
        }
        .MuiDialog-paper {
            margin: 0px;
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 0px);
        }
    }
    .take-photo-camera-dialog-popup {
        .MuiDialog-paperWidthSm {
            max-width: 900px !important;
            width: 100%;
            height: 100%;
            max-height: calc(100% - 16px);
        }
        .MuiDialog-paper {
            margin: 0px;
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 0px);
        }
    }
}
.text-red {
    color: var(--errorMessage);
    font-weight: 600;
    font-size: 20px;
}
.mui-btn-primary {
    background-color: var(--primaryButtonNormalStateColor) !important;
    color: var(--buttonTitleColor) !important;
    border: 2px solid var(--primaryButtonNormalStateColor) !important;
}

.chat-sidebar {
    .chat-list {
        .activity-histroy-list {
            .user-list-item {
                border-radius: 0px !important;
                padding: 5px 10px !important;
                .media {
                    padding: 8px 0px;
                    .media-body {
                      .media-body-text {
                        font-size: 12px;
                        line-height: 15px;
                        h4 {
                            margin-bottom: 0px !important;
                        }
                      }
                      .media-body-sub-text {
                        font-size: 14px;
                      }
                    }
                }
            }
            .item-active {
                border-radius: 0px !important;
            }
        }
    }
}
.chat-main-body {
    .email-detail-page-warrper {
        .top-head {
           .activity-status-details {
               font-size: 13px;
           }
           .satisfactory-no-button {
            color: #008C95;
            border: 1px solid #008C95;
            background-color: #FFFFFF;
            border-radius: 16px;
            min-width: 120px;
            .MuiButton-root {
                color: #008C95;
            }
         }
         .satisfactory-yes-button {
            border: 1px solid #FFFFFF;
            color: #FFFFFF;
            background-color: yellow;
            border-radius: 16px;
            min-width: 120px;
           .MuiButton-root {
             color: #FFFFFF;
           }
         }
        }
    }
}
.image-fit-cover{
    object-fit: cover;
}
.image-fit-contain{
    object-fit: contain;
}

.actvity-details-panel {
    .rct-block-title {
        padding: 6px 14px 5px 0px;
        .menu-icon-button {
            padding: 0px;
            margin-left: 5px;
        }
        h2 {
            margin-bottom: 0px;
        }

    }
    .steps-list-item {
        .MuiAvatar-colorDefault {
          color: #FFFFFF;
          background-color: #008C95;
        }
        h4 {
            margin-bottom: 0px;
        }
    }
    .collapse {
        padding: 0px 10px;
        .actions-button {
            .MuiButton-root {
                min-width: 290px;
                border-radius: 20px
            }
        }
    }
    .right-item-field-sm {
        min-width: 84px;
        text-align: right;
    }
    .right-item-field-lg {
        min-width: 145px;
        text-align: right;
    }
}

.dashboard-summary-panal-content {
    margin: 15px 10px;
    .heading-text {
        margin: 0px;
    }
    .rct-picker {
        .MuiInputAdornment-root {
            margin-right: 0px;
            margin-left: 2px;
        }
    }
}

.summary-list-item {
    background-color: #FFFFFF;
    .heading-text {
        font-weight: 500;
    }
}

.bullietin-post-viewer {
    .MuiDialog-container {
        .MuiDialog-paperWidthSm {
            max-width: 450px;
            min-height: 500px;
        }
        .MuiDialog-paper {
            margin: 0px;
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 0px);
        }
        video {
            width: 100%;
            height: calc(100vh - 100px);
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
            border-radius: 0px;
            background-color: #000;
        }
        iframe {
            height: calc(100vh - 100px);
        }
        .image-container {
            width: 100%;
            height: calc(100vh - 100px);
            background-color: #000;
            border: 2px solid #000;
            overflow-y: auto;
        }
    }
}
    
.client-contacts-list {
    height: 135px;
    overflow-x: auto;
    overflow-y: hidden;
    .client-contact {
        width: 140px;
        .user-later-avatar {
            font-size: 35px;
        }
        .MuiAvatar-root {
            width: 60px;
            height: 60px;
        }
    }
}
.start-workflow-popup {
    .start-workflow-container {
        min-height: 400px;
        @media(max-width: 700px) {
            height: calc(100vh - 155px);
        }
        @media(min-width: 701px) {
            height: calc(100vh - 250px);
        }
    }
   
}
.notices-list-panel{
    .MuiDialog-container {
        .MuiDialog-paperWidthSm {
            max-width: 450px;
            min-height: 500px;
        }
        .MuiDialog-paper {
            margin: 0px;
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 0px);
        }
        .MuiDialogContent-root {
            overflow-x: hidden;
        }
        @media(max-width: 700px) {
            video {
                height: calc(100vh - 155px);
            }
            iframe {
                height: calc(100vh - 155px);
            }
            .image-container {
                height: calc(100vh - 155px);
            }
            .ls-react-pdf {
                height: calc(100vh - 155px) !important;
            }
            .chat-container {
                height: calc(100vh - 100px) !important;
                .chat-item-list {
                    height: calc(100vh - 220px) !important;
                }
            }
            .empty-container {
                height: calc(100vh - 155px) !important;
            }
        }
        @media(min-width: 701px) {
            video {
                height: calc(100vh - 110px);
            }
            iframe {
                height: calc(100vh - 110px);
            }
            .image-container {
                height: calc(100vh - 110px);
            }
            .ls-react-pdf {
                height: calc(100vh - 110px) !important;
            }
            .chat-container {
                height: calc(100vh - 110px) !important;
                .chat-item-list {
                    height: calc(100vh - 230px) !important;
                    //height: 69vh !important;
                }
            }
            .empty-container {
                height: calc(100vh - 110px) !important;
            }
        }
        video {
            width: 100%;
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
            border-radius: 0px;
            background-color: #000;
        }
        .image-container {
            width: 100%;
            background-color: #000;
            border: 2px solid #000;
            overflow-y: auto;
        }
    }
}

.activity-course-viewer{
    .MuiDialog-container {
        .MuiDialog-paperWidthSm {
            max-width: 450px;
            min-height: 500px;
        }
        .MuiDialog-paper {
            margin: 0px;
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 0px);
        }
        .MuiDialogContent-root {
            overflow-x: hidden;
        }
        @media(max-width: 700px) {
          
            iframe {
                height: calc(100vh - 125px);
            }
            .empty-container {
                height: calc(100vh - 125px) !important;
            }
        }
        @media(min-width: 701px) {
           
            iframe {
                height: calc(100vh - 80px);
            }
          
            .empty-container {
                height: calc(100vh - 80px) !important;
            }
        }
    }
    @media(max-width: 700px) {
          
        iframe {
            height: calc(100vh - 125px);
        }
        .empty-container {
            height: calc(100vh - 125px) !important;
        }
    }
    @media(min-width: 701px) {
       
        iframe {
            height: calc(100vh - 80px);
        }
      
        .empty-container {
            height: calc(100vh - 80px) !important;
        }
    }
}

.bullietin-post-video-viewer{
    .MuiDialog-container {
        .MuiDialog-paperWidthSm {
            max-width: 450px;
            min-height: 500px;
        }
        .MuiDialog-paper {
            margin: 8px;
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 8px);
        }
    }
}

.bulletin-posts-list {
    height: 395px;
    .list-card-slider {
        height: 300px !important;
        overflow-y: auto;
    }
}

.disabled-button {
    padding: 9px 10px;
}
.filter-button-width {
    width: 150px;
}

.read-pending-badge {
    padding: 3px 5px 4px !important;
}

.hide-element {
    visibility: hidden;
}
.form-description {
    padding: 5px 10px;
} 
.notices-panel {
    .thumb-icon {
        .MuiButtonBase-root {
            .MuiSvgIcon-root {
              color: #fff !important;
            }
          }
    }
}
.flow-layout-help-image {
    border: 4px solid #134266;
    border-radius: 30px;
}
.list-layout-help-image {
    border: 2px solid #134266;
    border-radius: 15px;
}
.help-contact-navbar {
    border: 2px solid var(--headlineTextColor);
    border-radius: 5px;
}
.notice-alert-nav-title {
    margin-left: 88.5px;
}

.bottom-tab-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #008C95 ;
    color: #FFFFFF;
    border-top: 0.02rem solid lightgrey;
    height: 55px;
    z-index: 3;
    .MuiBadge-root{
        .MuiBadge-badge {
            top: -3px;
            right: 17px;
            background-color: red;
        }
    }
    .MuiAvatar-root {
        position: relative;
        width: 60px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        box-shadow: #008C95 0px 0px 0px 5px;
        transform: translateY(-22px);
        background-position: center;
        background-color: #008C95 ;
        padding-top: 5px;
    }
    .dashboard-image-container {
        position: relative;
        width: 72px;
        height: 62px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        box-shadow: #008C95 0px 0px 0px 5px;
        transform: translateY(-13px);
        background-position: center;
        background-color: #008C95 ;
        padding-top: 9px;
        .dashboard-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
        .dashboard-image-selected {
            border: 3px solid #fff;
        }
    }
    .empty-clients-tab {
        width: 35px;
    }
}

.middle-link {
    position: relative;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    box-shadow: var(--tabBarColor) 0px 0px 0px 5px;;
    transform: translateY(-20px);
    background-position: center;
  }
.bottom-nav-link {
    // font-size: 28px;
}

.main-content {
    padding-bottom: 50px;
}
.min-panel-height {
    min-height: 500px;
}
.min-dashboard-panel-height {
    min-height: calc(100vh - 40px);
}
.min-home-panel-height {
    min-height: calc(100vh - 200px);
}
.min-activity-history-detail-panel-height {
    min-height: calc(100vh - 50px);
}

.home-panel-spinner-container-height {
    height: calc(100vh - 50px);
    position: fixed !important
}
.min-client-panel-height {
    min-height: calc(100vh - 250px);
}

.client-panel-spinner-container-height {
    height: calc(100vh - 50px);
    position: fixed !important
}
.activity-preview-dialog-popup {
    .activity-speech-icon-button {
        border: 2px solid #008C95;
        color: #008C95;
        padding: 2px;
        box-shadow: 0px 0px 5px 1px #008C95;
        .MuiSvgIcon-root {
            font-size: 28px;
        }
    }
}
.more-screen-height {
    min-height: calc(100vh - 185px);
}

.virtaul-activities-list {
    padding-bottom: 50px;
    .ReactVirtualized__List {
        height: auto !important;
    }
}
.role-warning-icon {
    width: 18px !important;
}
.pending-task-action-button {
    width: 65px;
}
.quate-block {
    border-left: 2px solid #ccc7c7;
    padding-left: 16px;
}
.quate-block-feedback {
    border-left: 2px solid #f7c0c0;
    padding-left: 16px;
}
.task-warning-block {
    color: #FF3739;
}
