// stylelint-disable declaration-no-important

// Common values

// Sass list not in variables since it's not intended for customization.
$positions: static, relative, absolute, fixed, sticky;

@each $position in $positions {
  .position-#{$position} { position: $position !important; }
}

// Shorthand

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.fixed-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: $zindex-fixed;
}
.mobile-fixed-bottom {
  bottom: 50px !important;
  position: fixed;
}

// @media(max-width: 767px) {
//   .fixed-bottom {
//     position: fixed;
//     bottom: 50px !important;
//   }
// }

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }
}
