.notification-ls {
    p {
        font-weight: 600;
        font-size: 14px;
        span {
            font-weight: 400;
            font-size: 14px;
        }
    }
    h5 {
        font-weight: 600;
        font-size: 14px;
    }
    span {
        font-size: 12px;
        font-weight: 400;
    }
}