.rewards-header{
    width:100%;
    background: rgb(235, 242, 245);
}
.text-499{
    font-weight: 500;
}
.radius-10{
    border-radius: 10px;
}
.margin-bottom-20{
    margin-bottom: 20px;
}
.client-role-height{
    min-height:460px
}


.rewards-container {
    .reward-header {
        // padding: 9px 14px;
        padding: 0.9375rem 1.25rem;
        position: relative;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .css-to4x1n-MuiBadge-badge {
        right: 15px;
    }
}    

